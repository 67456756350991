<template>
  <div id="real-time">
    <!-- 实时数据 -->
    <div class="health">
      <!-- 左侧树 -->
      <app-tree ref="appTree" @select="onSelect"></app-tree>
      <!-- 右侧内容区 -->
      <div class="content">
        <!-- 点击企业要显示的数据 -->
        <div class="details-page" v-show="!show">
          <!-- 顶部标题 -->
          <h3 class="details-page-title">
            {{ $store.state.selectedData.name }}
          </h3>
          <!-- 实时数据 -->
          <h3 class="h3-title">实时数据</h3>
          <div>
            <!-- //废气 -->
            <div v-for="(item, index) in arr" :key="index + 'a1'">
              <div class="sign">
                <img
                  v-if="item[0].ConnectState == 1"
                  style="margin-right: 10px"
                  :src="WIFI"
                  alt=""
                />
                <img
                  v-else-if="item[0].ConnectState == 0"
                  style="margin-right: 10px"
                  :src="WIFI2"
                  alt=""
                />
                <span style="margin-right: 23px">{{
                  item[0].station_name
                }}</span>
                <span style="margin-right: 23px; color: #888">{{
                  item[0].itemtype_name
                }}</span>
                <span style="margin-right: 23px; font-size: 15px; color: red">{{
                  item[0].TS
                }}</span>
                <p style="flex: 1"></p>
                <p style="margin-right: 3px">
                  更新: {{ dateCYSJ(item[0].CYSJ) }}
                </p>
                <div class="btn" @click="onRefresh(1)">刷新</div>
              </div>
              <div class="enterprise-home-water">
                <div
                  v-for="(itemObj, index) in item[0].itemData"
                  :key="index + 'b1'"
                >
                  <div
                    v-if="itemObj.SS_XSFW == 1"
                    class="enterprise-home-public content-first"
                  >
                    <p>
                      <span
                        style="
                          border-bottom: 1px solid #deddde;
                          background: #54c9e9;
                          color: #333;
                          border-radius: 5px 5px 0 0;
                        "
                        >{{ itemObj.SC }}</span
                      >
                    </p>
                    <strong>{{ itemObj.SCZ }}</strong>
                    <p
                      v-if="
                        itemObj.SCUNIT_SS == '' || itemObj.SCUNIT_SS == null
                      "
                    >
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.SCNAME_SS }}<br />
                      </span>
                    </p>
                    <p v-else>
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.SCNAME_SS }}<br />({{ itemObj.SCUNIT_SS }})
                      </span>
                    </p>
                  </div>
                  <div
                    v-if="itemObj.SS_XSFW == 2"
                    class="enterprise-home-public content-first"
                  >
                    <p>
                      <span
                        style="
                          border-bottom: 1px solid #deddde;
                          background: #54c9e9;
                          color: #333;
                          border-radius: 5px 5px 0 0;
                        "
                        >{{ itemObj.SC }}</span
                      >
                    </p>
                    <strong>{{ itemObj.ZSZ }}</strong>
                    <p
                      v-if="
                        itemObj.ZSUNIT_SS == '' || itemObj.ZSUNIT_SS == null
                      "
                    >
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.ZSNAME_SS }}<br />
                      </span>
                    </p>
                    <p v-else>
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.ZSNAME_SS }}<br />({{ itemObj.ZSUNIT_SS }})
                      </span>
                    </p>
                  </div>
                  <div
                    v-if="itemObj.SS_XSFW == 3"
                    class="enterprise-home-public content-sec"
                  >
                    <p>
                      <span
                        style="
                          border-bottom: 1px solid #deddde;
                          background: #54c9e9;
                          color: #333;
                          border-radius: 5px 5px 0 0;
                        "
                        >{{ itemObj.SC }}</span
                      >
                    </p>
                    <div class="content-secd">
                      <div class="data">
                        <strong>{{ itemObj.SCZ }}</strong>
                        <!-- 	<span class="unit" style="line-height:20px;">
												{{itemObj.SCNAME_SS}}<br />({{itemObj.SCUNIT_SS}})
												</span> -->
                        <span
                          class="unit"
                          style="line-height: 20px"
                          v-if="
                            itemObj.SCUNIT_SS == '' || itemObj.SCUNIT_SS == null
                          "
                        >
                          {{ itemObj.SCNAME_SS }}<br />
                        </span>
                        <span class="unit" style="line-height: 20px" v-else>
                          {{ itemObj.SCNAME_SS }}<br />({{ itemObj.SCUNIT_SS }})
                        </span>
                      </div>
                      <div
                        class="data right"
                        style="border-left: 1px solid #eaeaea"
                      >
                        <strong>{{ itemObj.ZSZ }}</strong>
                        <span
                          class="unit"
                          style="line-height: 20px"
                          v-if="
                            itemObj.ZSUNIT_SS == '' || itemObj.ZSUNIT_SS == null
                          "
                          >{{ itemObj.ZSNAME_SS }}<br />
                        </span>
                        <span class="unit" style="line-height: 20px" v-else
                          >{{ itemObj.ZSNAME_SS }}<br />({{
                            itemObj.ZSUNIT_SS
                          }})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <a-empty v-if="arr.length<=0" /> -->

            <!-- //废水 -->
            <div v-for="(item, index) in arr1" :key="index + 'c1'">
              <div class="sign">
                <img
                  v-if="item[0].ConnectState == 1"
                  style="margin-right: 10px"
                  :src="WIFI"
                  alt=""
                />
                <img
                  v-else-if="item[0].ConnectState == 0"
                  style="margin-right: 10px"
                  :src="WIFI2"
                  alt=""
                />
                <span style="margin-right: 23px">{{
                  item[0].station_name
                }}</span>
                <span style="margin-right: 23px; color: #888">{{
                  item[0].itemtype_name
                }}</span>
                <span style="margin-right: 23px; font-size: 15px; color: red">{{
                  item[0].TS
                }}</span>
                <p style="flex: 1"></p>
                <p style="margin-right: 3px">
                  更新: {{ dateCYSJ(item[0].CYSJ) }}
                </p>
                <div @click="onRefresh(1)" class="btn">刷新</div>
              </div>

              <div class="enterprise-home-water">
                <div
                  v-for="(itemObj, index) in item[0].itemData"
                  :key="index + 'd1'"
                >
                  <div
                    v-if="itemObj.SS_XSFW == 1"
                    class="enterprise-home-public content-first"
                  >
                    <p>
                      <span
                        style="
                          border-bottom: 1px solid #deddde;
                          background: #d3ec8f;
                          color: #333;
                          border-radius: 5px 5px 0 0;
                        "
                        >{{ itemObj.SC }}</span
                      >
                    </p>
                    <strong>{{ itemObj.SCZ }}</strong>
                    <p
                      v-if="
                        itemObj.SCUNIT_SS == '' || itemObj.SCUNIT_SS == null
                      "
                    >
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.SCNAME_SS }}<br />
                      </span>
                    </p>
                    <p v-else>
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.SCNAME_SS }}<br />({{ itemObj.SCUNIT_SS }})
                      </span>
                    </p>
                  </div>
                  <div
                    v-if="itemObj.SS_XSFW == 2"
                    class="enterprise-home-public content-first"
                  >
                    <p>
                      <span
                        style="
                          border-bottom: 1px solid #deddde;
                          background: #d3ec8f;
                          color: #333;
                          border-radius: 5px 5px 0 0;
                        "
                        >{{ itemObj.SC }}</span
                      >
                    </p>
                    <strong>{{ itemObj.ZSZ }}</strong>
                    <p
                      v-if="
                        itemObj.ZSUNIT_SS == '' || itemObj.ZSUNIT_SS == null
                      "
                    >
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.ZSNAME_SS }}<br />
                      </span>
                    </p>
                    <p v-else>
                      <span class="unit" style="line-height: 20px"
                        >{{ itemObj.ZSNAME_SS }}<br />({{ itemObj.ZSUNIT_SS }})
                      </span>
                    </p>
                  </div>
                  <div
                    v-if="itemObj.SS_XSFW == 3"
                    class="enterprise-home-public content-sec"
                  >
                    <p>
                      <span
                        style="
                          border-bottom: 1px solid #deddde;
                          background: #d3ec8f;
                          color: #333;
                          border-radius: 5px 5px 0 0;
                        "
                        >{{ itemObj.SC }}</span
                      >
                    </p>
                    <div class="content-secd">
                      <div class="data">
                        <strong>{{ itemObj.SCZ }}</strong>
                        <span
                          class="unit"
                          style="line-height: 20px"
                          v-if="
                            itemObj.SCUNIT_SS == '' || itemObj.SCUNIT_SS == null
                          "
                          >{{ itemObj.SCNAME_SS }}<br />
                        </span>
                        <span class="unit" style="line-height: 20px" v-else
                          >{{ itemObj.SCNAME_SS }}<br />({{
                            itemObj.SCUNIT_SS
                          }})
                        </span>
                      </div>
                      <div
                        class="data right"
                        style="border-left: 1px solid #eaeaea"
                      >
                        <strong>{{ itemObj.ZSZ }}</strong>
                        <span
                          class="unit"
                          style="line-height: 20px"
                          v-if="
                            itemObj.ZSUNIT_SS == '' || itemObj.ZSUNIT_SS == null
                          "
                          >{{ itemObj.ZSNAME_SS }}<br />
                        </span>
                        <span class="unit" style="line-height: 20px" v-else
                          >{{ itemObj.ZSNAME_SS }}<br />({{
                            itemObj.ZSUNIT_SS
                          }})
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 报警信息 -->
          <div>
            <div class="nav-call">
              <h3 class="h3-title">报警信息</h3>
              <div style="flex: 1"></div>
              <div @click="onRefresh(2)" class="btn">刷新</div>
            </div>
            <div
              class="enterprise-Home-alarmBottom"
              v-for="(item, index) in callData"
              :key="index"
            >
              <div
                class="enterprise-Home-alarmBottomTitle"
                :class="setClass(item.BJLX)"
              >
                <p>
                  <strong>{{ item.FZ }}</strong>
                </p>
                <p v-if="item.UNIT == '' || item.UNIT == null">
                  <span>{{ item.ItemCode }}</span>
                </p>
                <p v-else>
                  <span>{{ item.ItemCode }}({{ item.UNIT }})</span>
                </p>
              </div>
              <div class="erprise-Home-alarmBottomContent">
                <div
                  style="float: left; line-height: 46px"
                  class="enterprise-Home-alarmBottom-CompanyName"
                >
                  <strong style="font-size: 20px">{{
                    item.station_name
                  }}</strong>
                </div>
                <div style="float: right; line-height: 46px">
                  <span>{{ item.BJSJ }}</span>
                </div>
                <div style="clear: both"></div>
                <p>
                  {{ item.AlarmMsg }}
                </p>
              </div>
            </div>
            <a-empty v-if="callData.length <= 0" />
          </div>
        </div>
        <!-- 点击 区域 要显示的内容 -->
        <div class="table-page" v-show="show">
          <h3>{{ $store.state.selectedData.name }}</h3>
          <div class="search-shell">
            <p class="title">实时数据</p>
            <div style="flex: 1"></div>
            <div class="select-all">
              <div class="label">基站类型</div>
              <!-- <base-select 
								v-bind:width="width" 
								@baseChange="handleChange"
							></base-select> -->
              <a-select
                :default-value="JZLX"
                v-bind:style="{ width: '120px' }"
                @change="handleChange"
              >
                <a-select-option
                  v-for="item in baseData"
                  :value="item.FieldBH"
                  :key="item.FieldBH"
                >
                  {{ item.FieldMC }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="tips-shell">
            <div style="flex: 1"></div>
            <div class="tips-some" style="margin-right: 22px">
              <div class="label">状态:</div>
              <div class="tips-text">
                <div class="one-of">
                  <img :src="imgUrl[0]" alt="" />
                  <span>在线</span>
                </div>
                <div class="one-of">
                  <img :src="imgUrl[1]" alt="" />
                  <span>离线</span>
                </div>
                <div class="one-of">
                  <img :src="imgUrl[5]" alt="" />
                  <span>停运在线</span>
                </div>
                <div class="one-of">
                  <img :src="imgUrl[6]" alt="" />
                  <span>停运离线</span>
                </div>
              </div>
            </div>
            <!-- <div class="tips-some">
							<div class="label">报警颜色:</div>
							<div class="tips-text">
								<div class="one-of">
									<img :src="imgUrl[2]" alt="">
									<span>异常</span>
								</div>
								<div class="one-of">
									<img :src="imgUrl[3]" alt="">
									<span>超标</span>
								</div>
								<div class="one-of">
									<img :src="imgUrl[4]" alt="">
									<span>预警</span>
								</div>
							</div>
						</div> -->
          </div>
          <!-- table 表格 -->
          <div class="table-shell">
            <a-table
              :scroll="{ x: 1200, y: 'calc(100vh - 180px)' }"
              :columns="columns"
              size="small"
              :row-key="(record) => record.station_bh"
              :data-source="data"
              :loading="loading"
              bordered
              :pagination="false"
              :customRow="rowClick"
            >
              <!-- 在线或者离线 -->
              <span slot="ConnectState" slot-scope="ConnectState">
                <img v-if="ConnectState == 1" :src="imgUrl[0]" alt="" />
                <img v-else-if="ConnectState == 0" :src="imgUrl[1]" alt="" />
                <img v-else-if="ConnectState == 2" :src="imgUrl[5]" alt="" />
                <img v-else :src="imgUrl[6]" alt="" />
              </span>
              <!-- 字体改变颜色 -->
              <span
                slot="filterfff"
                slot-scope="text"
                v-html="formatTable(text)"
              >
              </span>
            </a-table>
            <div style="display: flex; padding-bottom: 10px">
              <div style="flex: 1"></div>
              <a-pagination
                show-quick-jumper
                :page-size="pageSize"
                v-model="current"
                :total="total"
                :show-total="
                  (total, range) =>
                    `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`
                "
                @change="onChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTree from "@/components/AppTree.vue";
import moment from "moment";
import BaseSelect from "../../components/BaseSelect.vue";

import dayjs from "dayjs";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);

import { http } from "../../utils/Http.js";

export default {
  data() {
    return {
      DATE: new Date(),
      width: "120px",

      loading: false,

      //  点击左侧树，进行显示表格或者详情页面的控制按钮。false：显示详情，隐藏表格。true:隐藏详情、显示表格，
      show: true,

      // table 相关
      data: [],
      columns: [],
      pageSize: 20,
      total: 0,
      current: 1,

      imgUrl: [
        require("../../assets/img/pics/017.png"),
        require("../../assets/img/pics/018.png"),
        require("../../assets/img/pics/014.png"),
        require("../../assets/img/pics/015.png"),
        require("../../assets/img/pics/016.png"),
        require("../../assets/img/pics/stoponline.png"),
        require("../../assets/img/pics/stopunline.png"),
      ],

      // defaultValue:
      baseData: [],
      JZLX: "1", //基站类型

      area_num: this.$store.state.expandedKeys, //地区编号
      WIFI: require("../../assets/img/pics/WIFI.png"),
      WIFI2: require("../../assets/img/pics/WIFI2.png"),

      //废气数据
      arr: [],

      //废水数据
      arr1: [],

      //报警数据
      callData: [],
    };
  },

  components: {
    AppTree,
    BaseSelect,
  },

  computed: {
    dateFormat() {
      return function(value) {
        let dateNew = dayjs(value).format("YYYY-MM-DD");
        return dateNew;
      };
    },

    dateCYSJ() {
      return function(value) {
        let dateNew = "";
        if (value) {
          dateNew = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
        }
        return dateNew;
      };
    },

    setClass() {
      return function(value) {
        if (value == 1) return "enterprise-color1"; //异常
        if (value == 2) return "enterprise-color2"; //超标
        if (value == 3) return "enterprise-color3"; //预警
      };
    },
  },

  mounted() {
    let me = this;
    me.SYS_DB_Dictionary();
  },

  methods: {
    moment,
    rowClick(record, index) {
      var me = this;
      return {
        on: {
          // click: () => {
          //   me.onStationRowClick(record);
          // },
          dblclick:()=>{
            me.onStationRowClick(record);
          }
        },
      };
    },
    onStationRowClick(record) {
      var me=this;
      if (record.EnterpriseBH) {
        let EnterpriseBH = record.EnterpriseBH.split("|||")[0];
        me.$refs.appTree.selectNode(EnterpriseBH)
        //return;
        // me.show = false;
        // me.HJJC_STATION_GAS_WATER_REALDATA_EX1(); // 基站实时数据
        // me.HJJC_QLAMABSTRACT(); // 报警摘要
      }
    },
    /**
     * 基站类型
     */
    SYS_DB_Dictionary() {
      var me = this;
      http({
        url: "/data/q30/AutoTable_Select.aspx?TableName=SYS_DB_Dictionary",
        data: {
          Module: "BD",
          DataWhere: "LX=8",
        },
        success(obj) {
          // console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            me.baseData = obj.Data;
          }
        },
        failure(obj) {},
        me,
      });
    },

    //树点击
    onSelect(item, parent, type) {
      // // // console.log(item);
      // // // console.log(parent);
      // console.log(`当前的LX：${item.Lx}------5:是叶子节点。`);
      let me = this;
      if (type == 1) {
        me.HJJC_STATION_GAS_WATER_REALDATA_EX1();
        me.HJJC_QLAMABSTRACT();
      } else {
        // 在本页面 Lx == 5 就是叶子节点。
        // console.log(typeof item.Lx)
        if (item.Lx == 5) {
          me.show = false;
          me.HJJC_STATION_GAS_WATER_REALDATA_EX1(); // 基站实时数据
          me.HJJC_QLAMABSTRACT(); // 报警摘要
        } else {
          me.show = true;
          me.getTableDeta();
        }
      }
    },

    //选择基站类型
    handleChange(item) {
      let me = this;
      // console.log(item);
      me.JZLX = item;
      me.getTableDeta();
    },

    //分页
    onChange(pageNumber) {
      this.current = pageNumber;
      this.getTableDeta();
    },

    //刷新
    onRefresh(type) {
      if (type == 1) {
        this.HJJC_STATION_GAS_WATER_REALDATA_EX1();
        this.HJJC_QLAMABSTRACT();
      } else {
        this.HJJC_QLAMABSTRACT();
      }
    },

    /**
     * 获取table数据
     */
    getTableDeta() {
      var me = this;

      let item = me.$store.state.selectedData;
      var Lx = item.Lx;
      var BH = item.id;
      let area_num = "";
      console.log(Lx);
      switch (Lx) {
        case "1":
          area_num = ""; // 2020-12-05:全国传成空。
          break;
        case "2":
          area_num = BH;
          break;
        case "3":
          area_num = BH;
          break;
        case "4":
          area_num = BH;
          break;
      }

      let params = {
        CKM: me.$store.state.loggedIn.CKM,
        area_num,
        Lx: me.JZLX || "1",
        PAGESIZE: me.pageSize,
        PAGENUMBER: me.current,

        // start: (me.current - 1) * me.pageSize,
        // limit: me.pageSize,
      };

      console.log("table 的入参：", params);
      me.loading = true;

      http({
        url: "/data/q30/HJJC_STATION_GAS_WATER_REALDATA_areaNum.aspx",
        data: params,
        success(obj) {
          console.log("获取table数据:::", obj);
          me.loading = false;
          for (let i = 0; i < obj.Data2.length; i++) {
            if (obj.Data2[i].TS == 1) {
              if (obj.Data2[i].ConnectState == 1) {
                obj.Data2[i].ConnectState = 2; //表示停运在线
              } else {
                obj.Data2[i].ConnectState = 3; //表示停运离线
              }
            }
          }
          me.data = me.formatData(obj); // 格式化data数据
          // me.data = obj.Data2;
          if (obj.Data3 && obj.Data3.length > 0) {
            me.total = obj.Data3[0].ROWSCOUNT;
          }
          let columns = me.formatColumns(obj.Data, obj.Data1);
          console.log("columns::", columns);
          me.columns = columns;
        },
        failure(obj) {
          me.loading = false;
        },
        me,
      });
    },

    HJJC_STATION_GAS_WATER_REALDATA_EX1() {
      var me = this;
      http({
        url: "/data/HJJC_STATION_GAS_WATER_REALDATA_EX1.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          EnterpriseBH: me.$store.state.selectedKeys,
        },
        success(obj) {
          console.log(obj);

          var arr = new Array(); //废气
          var arr1 = new Array(); //废水

          for (var i = 0; i < 100; i++) {
            var data = obj["Data" + (i == 0 ? "" : i)];
            if (data && data.length > 0) {
              // // // // // console.log(data);
              if (
                data[0].itemkind == 1 ||
                data[0].itemkind == 3 ||
                data[0].itemkind == 4
              ) {
                //废气
                // arr = [...arr, ...data];
                arr.push(me.getStations(data));
              } else {
                //废水
                // arr1 = [...arr1, ...data];
                arr1.push(me.getStationGs(data));
              }
            }
          }

          me.arr = arr;
          me.arr1 = arr1;
          // // // // // console.log(me.arr);
          // // // // // console.log(me.arr1);
        },
        failure(obj) {},
        me,
      });
    },

    /**
     * 报警摘要
     */
    HJJC_QLAMABSTRACT() {
      var me = this;
      http({
        url: "/data/HJJC_QLAMABSTRACT.aspx",
        data: {
          CKM: me.$store.state.loggedIn.CKM,
          EnterpriseBH: me.$store.state.selectedKeys,
          ITEM: 20000,
        },
        success(obj) {
          console.log(obj);
          for (let i = 0; i < obj.Data.length; i++) {
            obj.Data[i].BJSJ = obj.Data[i].BJSJ.replace("T", " ");
          }
          me.callData = obj.Data;
        },
        failure(obj) {},
        me,
      });
    },

    //协议类型为废气时处理数据函数
    getStations(data) {
      var itemArr = [];

      // // // // // console.log(data);
      try {
        if (data && data.length) {
          console.log(data);
          for (var i = 0; i < data.length; i++) {
            if (data[i].CYSJ) {
              data[i].CYSJ = data[i].CYSJ.replace("T", " ");
            }
            if (data[i].TS == 0) {
              //正常
              data[i].TS = "";
            } else {
              //停运
              data[i].TS = "停运";
            }
            if (i == 0) {
              var newNode = {
                itemtype_name: data[i].itemtype_name,
                station_bh: data[i].station_bh,
                station_name: data[i].station_name,
                CYSJ: data[i].CYSJ,
                ConnectState: data[i].ConnectState,
                TS: data[i].TS,
                itemData: [],
              };
              if (data[i].SS_XSFW != 0) {
                for (var j = 0; j < data.length; j++) {
                  var node1 = data[j];
                  if (
                    node1.station_bh == data[0].station_bh &&
                    node1.QYFLAG == 1
                  ) {
                    var newNodeson = {
                      ConnectState: data[j].ConnectState,
                      ItemCode: data[j].ItemCode,
                      ORD: data[j].ORD,
                      SC: data[j].SC,
                      SCZ: data[j].SCZ,
                      SCNAME_SS: data[j].SCNAME_SS,
                      SCUNIT_SS: data[j].SCUNIT_SS,
                      ZSZ: data[j].ZSZ,
                      ZSBS: data[j].ZSBS,
                      ZSNAME_SS: data[j].ZSNAME_SS,
                      ZSUNIT_SS: data[j].ZSUNIT_SS,
                      station_bh: data[j].station_bh,
                      UNIT: data[j].UNIT,
                      SS_XSFW: data[j].SS_XSFW,
                    };
                    newNode.itemData.push(newNodeson);
                  }
                }
              }
              itemArr.push(newNode);
            } else {
              var node = data[i];
              var nodeO = data[i - 1];
              if (node.station_bh != nodeO.station_bh) {
                var newNode = {
                  itemtype_name: data[i].itemtype_name,
                  station_bh: data[i].station_bh,
                  station_name: data[i].station_name,
                  CYSJ: data[i].CYSJ,
                  ConnectState: data[i].ConnectState,
                  TS: data[i].TS,
                  itemData: [],
                };
                if (data[i].SS_XSFW != 0) {
                  for (var j = 0; j < data.length; j++) {
                    var node1 = data[j];
                    if (
                      node1.station_bh == node.station_bh &&
                      node1.QYFLAG == 1
                    ) {
                      var newNodeson = {
                        ConnectState: data[j].ConnectState,
                        ItemCode: data[j].ItemCode,
                        ORD: data[j].ORD,
                        SC: data[j].SC,
                        SCZ: data[j].SCZ,
                        SCNAME_SS: data[j].SCNAME_SS,
                        SCUNIT_SS: data[j].SCUNIT_SS,
                        ZSZ: data[j].ZSZ,
                        ZSBS: data[j].ZSBS,
                        ZSNAME_SS: data[j].ZSNAME_SS,
                        ZSUNIT_SS: data[j].ZSUNIT_SS,
                        station_bh: data[j].station_bh,
                        UNIT: data[j].UNIT,
                        SS_XSFW: data[j].SS_XSFW,
                      };
                      newNode.itemData.push(newNodeson);
                    }
                  }
                }
                itemArr.push(newNode);
              }
            }
          }
        }
      } catch (err) {}
      // // // // // console.log(itemArr)
      return itemArr;
    },

    //协议类型为废水时处理数据函数
    getStationGs(data) {
      // // // // console.log(data);
      var itemArr = [];
      try {
        if (data && data.length) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].CYSJ) {
              data[i].CYSJ = data[i].CYSJ.replace("T", " ");
            }
            if (data[i].TS == 0) {
              //正常
              data[i].TS = "";
            } else {
              //停运
              data[i].TS = "停运";
            }
            // // // // console.log(data[i].CYSJ);
            // // // // // console.log(Ext.util.Format.date(new Date(data[i].CYSJ), 'Y-m-d H:i:s'));
            if (i == 0) {
              var newNode = {
                itemtype_name: data[i].itemtype_name,
                station_bh: data[i].station_bh,
                station_name: data[i].station_name,
                CYSJ: data[i].CYSJ,
                ConnectState: data[i].ConnectState,
                TS: data[i].TS,
                itemData: [],
              };
              if (data[i].SS_XSFW != 0) {
                // // // // console.log(1);
                for (var j = 0; j < data.length; j++) {
                  var node1 = data[j];
                  if (
                    node1.station_bh == data[0].station_bh &&
                    node1.QYFLAG == 1
                  ) {
                    var newNodeson = {
                      ConnectState: data[j].ConnectState,
                      ItemCode: data[j].ItemCode,
                      ORD: data[j].ORD,
                      SC: data[j].SC,
                      SCZ: data[j].SCZ,
                      SCNAME_SS: data[j].SCNAME_SS,
                      SCUNIT_SS: data[j].SCUNIT_SS,
                      ZSZ: data[j].ZSZ,
                      ZSBS: data[j].ZSBS,
                      ZSNAME_SS: data[j].ZSNAME_SS,
                      ZSUNIT_SS: data[j].ZSUNIT_SS,
                      station_bh: data[j].station_bh,
                      UNIT: data[j].UNIT,
                      SS_XSFW: data[j].SS_XSFW,
                    };
                    newNode.itemData.push(newNodeson);
                  }
                }
              }
              itemArr.push(newNode);
            } else {
              var node = data[i];
              var nodeO = data[i - 1];
              if (node.station_bh != nodeO.station_bh) {
                var newNode = {
                  itemtype_name: data[i].itemtype_name,
                  station_bh: data[i].station_bh,
                  station_name: data[i].station_name,
                  ConnectState: data[i].ConnectState,
                  TS: data[i].TS,
                  CYSJ: data[i].CYSJ,
                  itemData: [],
                };
                if (data[i].SS_XSFW != 0) {
                  // // // // console.log(2);
                  for (var j = 0; j < data.length; j++) {
                    var node1 = data[j];
                    if (
                      node1.station_bh == node.station_bh &&
                      node1.QYFLAG == 1
                    ) {
                      var newNodeson = {
                        ConnectState: data[j].ConnectState,
                        ItemCode: data[j].ItemCode,
                        ORD: data[j].ORD,
                        SC: data[j].SC,
                        SCZ: data[j].SCZ,
                        SCNAME_SS: data[j].SCNAME_SS,
                        SCUNIT_SS: data[j].SCUNIT_SS,
                        ZSZ: data[j].ZSZ,
                        ZSBS: data[j].ZSBS,
                        ZSNAME_SS: data[j].ZSNAME_SS,
                        ZSUNIT_SS: data[j].ZSUNIT_SS,
                        station_bh: data[j].station_bh,
                        UNIT: data[j].UNIT,
                        SS_XSFW: data[j].SS_XSFW,
                      };
                      newNode.itemData.push(newNodeson);
                    }
                  }
                }
                itemArr.push(newNode);
              }
            }
          }
        }
      } catch (err) {}

      // // // // // console.log(itemArr)

      if (data && data.length > 0) {
        try {
          for (var k = 0; k < itemArr.length; k++) {
            itemArr[k].aaaa = 0;
          }
        } catch (err) {}
      } else {
        itemArr = [
          {
            aaaa: 1,
          },
        ];
      }

      return itemArr;
    },

    formatColumns(parentAry, childrenAry) {
      // console.time();
      // var tree = [];
      var tree = parentAry.map((p) => {
        p.title = p.SC;
        p.align = "center";
        p.children = childrenAry.filter((c) => {
          if (p.ItemCode == c.ItemCode) {
            if (c.ACTUAL_UNIT == "" || c.ACTUAL_UNIT == null) {
              c.title = c.ZT;
            } else {
              c.title = c.ZT + "(" + c.ACTUAL_UNIT + ")";
            }
            c.width = 128;
            c.align = "center";
            c.dataIndex = c.MC;
            c.scopedSlots = { customRender: "filterfff" };
            return c;
          }
        });
        return p;
      });

      tree.unshift({
        title: "基站名称",
        dataIndex: "station_name",
        width: 128,
        align: "center",
      });

      tree.unshift({
        title: "公司名称",
        dataIndex: "EnterpriseMC",
        width: 128,
        align: "center",
      });

      tree.unshift({
        title: "状态",
        dataIndex: "ConnectState",
        width: 60,
        align: "center",
        fixed: "left",
        scopedSlots: { customRender: "ConnectState" },
      });
      return tree;
    },

    /**
     * 格式化 data 数据
     * obj：全部的ajax返回的数据
     */
    formatData(obj) {
      let me = this;
      // Data2:详细信息。Data3：平均/最大/最小/样本。Data4：排放总量。
      let { Data2 } = obj;
      // // // console.log(Data2);

      let newData2 = me.getNewObj(Data2);
      console.log(newData2);
      // let data = [...Data2, ...Data3, d4];
      // console.log(newData2);
      return newData2;
    },
    getNewObj(list) {
      // // // console.log(list)
      let me = this;
      let newList = [];
      list.forEach((item) => {
        let newObj = {};
        for (let i in item) {
          // 先判断是否“ZT”结尾
          if (i == "HOUR") {
            // TJLX: ["1"], // 数据类型：1：小时数据。2：日数据。。4：月数据
            let tjlx = me.TJLX[0];
            if (tjlx == 1 || tjlx == "1") {
              newObj[i] = item["RQ"] + " " + item[i];
            } else if (tjlx == 4 || tjlx == "4") {
              newObj[i] = item["YEAR"] + " " + item[i];
            } else {
              newObj[i] = item[i];
            }
          } else if (i == "ConnectState") {
            newObj[i] = item[i];
          } else if (i == "station_name") {
            newObj[i] = item[i];
          } else if (i == "EnterpriseMC") {
            newObj[i] = item[i];
          } else if (i.indexOf("ZT") == -1) {
            // // // console.log((typeof item[i+"ZT"]) == "undefined");
            newObj[i] = (item[i] || "") + "|||" + item[i + "ZT"];
          }
        }
        // // // console.log(newObj);
        newList.push(newObj);
      });
      // // // console.log(newList);
      return newList;
    },

    /**
     * 格式化table的数据展示。
     */
    formatTable(text) {
      let me = this;
      // // console.log(text);
      if (typeof text == "string") {
        let t = text.split("|||")[0],
          start = text.split("|||")[1];
        // // // console.log(t);

        if (start == 1 || start == "1") {
          return `<span style="color:#884898; font-weight:700;">${t}</span>`;
        } //异常
        if (start == 2) {
          return `<span style="color:#e22222; font-weight:700;">${t}</span>`;
        } //超标
        if (start == 3) {
          return `<span style="color:#f67c49; font-weight:700;">${t}</span>`;
        } //预警
        return `<span >${t}</span>`;
      } else {
        return `<span >${text.split("|||")[0]}</span>`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#real-time {
  font-family: PingFangSC-Medium, PingFang SC;
  height: 100%;

  .health {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    height: 100%;

    .content {
      flex: 1;
      width: 0;
      background-color: #ffffff;
      padding: 20px 20px 0;

      .details-page {
        .details-page-title {
          text-align: center;
          line-height: 60px;
          margin-bottom: 0;
          font-size: 24px;
        }

        .sign {
          display: flex;
          align-items: center;
          height: 30px;
          color: #3c3c3c;
          font-size: 16px;
          font-style: normal;

          img {
            width: 21px;
            height: 17px;
          }

          // .btn {
          // 	width: 48px;
          // 	height: 20px;
          // 	line-height: 20px;
          // 	background-color: #1691ff;
          // 	border-radius: 4px;
          // 	border: none;
          // 	color: #fefefe;
          // 	cursor: pointer;
          // 	text-align: center;
          // 	font-size: 14px;
          // }
        }

        .nav-call {
          display: flex;
          align-items: center;
          height: 60px;
        }

        .h3-title {
          font-size: 24px;
          color: #1691ff;
          line-height: 60px;
          text-align: left;
          margin-bottom: 0;
        }

        .btn {
          width: 48px;
          height: 20px;
          line-height: 20px;
          background-color: #1691ff;
          border-radius: 4px;
          border: none;
          color: #fefefe;
          cursor: pointer;
          text-align: center;
          font-size: 14px;
        }

        .enterprise-home-water {
          margin: 8px;
          overflow: hidden;

          .enterprise-home-public {
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #f5f5f5;
            box-shadow: 4px 4px 1px rgba(245, 245, 245, 0.5);
            color: #8e8e8e;
            text-align: center;

            p {
              margin: 0;
              padding: 0;

              span {
                font-size: 18px;
                display: block;
                padding: 4px 0;
                line-height: 26px;
              }

              .unit {
                font-size: 14px;
                padding: 4px 0px 6px;
              }
            }

            strong {
              font-size: 22px;
              display: block;
              word-wrap: break-word;
              height: 32px;
              line-height: 40px;
            }

            .content-secd {
              display: flex;

              .data {
                flex: 1;

                .strong {
                  font-size: 22px;
                  display: block;
                  word-wrap: break-word;
                  height: 32px;
                  line-height: 40px;
                }

                .unit {
                  font-size: 14px;
                  padding: 4px 0px 6px;
                }
              }
            }
          }

          .content-sec {
            float: left;
            width: 32%;
            min-height: 119px;
            margin: 0 0.6% 20px;
          }

          .content-first {
            width: 16%;
            float: left;
            min-height: 119px;
            margin: 0 0.3% 20px;
          }
        }

        .enterprise-Home-alarmBottom {
          margin: 10px 8px 0;
          display: flex;
          border: 1px solid #dedfe0;
          border-radius: 5px;
          box-shadow: #d5d5d5 0px 2px 1px;

          .enterprise-Home-alarmBottomTitle {
            width: 140px;
            background-color: #e22222;
            float: left;
            color: #fff;
            border-radius: 5px;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            padding: 22px;

            p {
              margin: 0;
              padding: 0;
            }

            p:first-child {
              font-size: 26px;
              line-height: 40px;
            }
          }

          .enterprise-color1 {
            background-color: #884898;
          }

          .enterprise-color2 {
            background-color: #e22222;
          }

          .enterprise-color3 {
            background-color: #f67c49;
          }

          .erprise-Home-alarmBottomContent {
            flex: 1;
            float: left;
            padding: 10px;
            font-size: 18px;
            color: #888;
            line-height: 22px;

            .enterprise-Home-alarmBottom-CompanyName {
              width: 65%;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }

      .table-page {
        h3 {
          text-align: center;
          font-size: 18px;
          height: 25px;
          line-height: 25px;
          margin-bottom: 24px;
        }

        .search-shell {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
          }

          .select-all {
            display: flex;
            align-items: center;

            .label {
              width: 70px;
              font-size: 14px;
              color: #3f3f3f;
              line-height: 20px;
            }
          }
        }

        .tips-shell {
          display: flex;
          align-items: center;
          height: 20px;
          line-height: 20px;
          color: #3f3f3f;
          margin-bottom: 24px;

          .tips-some {
            display: flex;
            align-items: center;

            .tips-text {
              display: flex;
              align-items: center;

              .one-of {
                color: #000000;
                display: flex;
                align-items: center;
                margin-left: 12px;

                img {
                  margin-right: 8px;
                }
              }
            }
          }
        }

        .table-shell {
          width: 100%;
          // height: calc(100% - 145px);
          display: flex;
          flex-direction: column;

          @import "../../assets/css/AppHome.scss";

          &::v-deep .ant-table-thead {
            background: #ffffff;
          }

          .ant-table-wrapper {
            width: 100%;
            margin-bottom: 10px;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.table-shell .ant-table-thead > tr > th {
  color: #181818 !important;
  font-size: 14px !important;
  padding: 4px 8px;
}
</style>
